import React from 'react';

const CurrentProjects = () => {
  const projects = [
    { 
      id: 1, 
      title: "FC Chalais", 
      logo: "/Logo FC Chalais.png", 
      url: "https://fcchalais.a4c.ch" 
    },
    { 
      id: 2, 
      title: "FC Saint-Léonard", 
      logo: "/Logo FC Saint-Léonard.png", 
      url: "https://fcsaint-leonard.a4c.ch" 
    },
  ];

  return (
    <section id="projects" className="py-16 bg-primary">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl text-gray-100 font-bold mb-12">Nos Projets</h2>
        <div className="flex flex-wrap justify-center gap-6">
          {projects.map((project) => (
            <a
              key={project.id}
              href={project.url}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-lg shadow-lg p-6 w-80 hover:shadow-2xl transition text-center"
            >
              <img
                src={project.logo}
                alt={`${project.title} Logo`}
                className="w-24 h-24 mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold">{project.title}</h3>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CurrentProjects;
