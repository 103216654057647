import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-light text-dark py-4 px-6 flex justify-between items-center border">
      <div className="flex items-center space-x-3">
        <img src="/logo.svg" alt="Logo" className="w-8 h-8" />
        <span className="text-xl font-bold">All4Club</span>
      </div>
      <ul className="flex space-x-6">
        <li><a href="#hero" className="hover:text-primary">Accueil</a></li>
        <li><a href="#projects" className="hover:text-primary">Projets</a></li>
        <li><a href="#contact" className="hover:text-primary">Contact</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
