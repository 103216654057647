import React from 'react';

const HeroSection = () => {
  return (
    <section
      id="hero"
      className="flex flex-col md:flex-row items-center px-6 md:px-12"
      style={{ minHeight: 'calc(100vh - 65px)' }}
    >
      <div className="flex-1 text-center md:text-left">
        <h1 className="text-5xl font-bold text-gray-800 mb-6 mt-8 md:mt-0">
          Bienvenue sur All4Club
        </h1>
        <p className="text-lg text-gray-600 mb-6 mt-8 md:mt-0">
          La solution simple et moderne pour votre club !
        </p>
        <a
          href="#projects"
          className="bg-primary text-white px-6 py-3 rounded-lg text-lg hover:bg-primary-dark"
        >
          Découvrez les Projets
        </a>
      </div>

      <div className="flex-1 flex justify-center mt-8 md:mt-0">
        <img
          src="/All4ClubProjects.png"
          alt="Hero"
          className="w-full max-w-md md:max-w-lg rounded-lg mb-8 animate-float"
        />
      </div>
    </section>
  );
};

export default HeroSection;
