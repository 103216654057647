import React from 'react';

const ContactSection = () => {
  return (
    <section id="contact" className="py-16 bg-white text-dark">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl font-bold mb-12">Contactez-nous !</h2>
        <p className="text-lg mb-6">
          N'hésitez pas à nous contacter pour des questions, des remarques, ou toute autre information !
        </p>
        <div className="flex justify-center space-x-8 text-2xl">
          <a
            href="mailto:info@all4club.ch"
            className="hover:text-primary transition"
            aria-label="Email"
          >
            <i className="fas fa-envelope"></i>
          </a>
          <a
            href="https://www.instagram.com/all4club_/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary transition"
            aria-label="Instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61568930080619&mibextid=LQQJ4d&rdid=i4s6zjS1QNdnD4XA&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F15TCdpNFgd%2F%3Fmibextid%3DLQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary transition"
            aria-label="Facebook"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/all4club/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary transition"
            aria-label="LinkedIn"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
