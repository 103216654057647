import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white text-center py-2">
  <p> All4Club © 2024. Tout droits réservés.</p>
</footer>

  );
};

export default Footer;
