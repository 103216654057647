import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import CurrentProjects from './components/CurrentProjects';
import ContactSection from './components/ContactSession';
import Footer from './components/Footer';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <HeroSection />
      <CurrentProjects />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
